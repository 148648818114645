.boxes {
    width: 1200px; max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    @include tablet{
        flex-wrap: wrap;
    }
}
.boxes__boxContainer {
    width: 33.33%;
    padding: 10px;

    @include tablet{
        width: auto;
    }
}
.boxes__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px 20px 25px;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    color: $white;
}
.boxes__box__title {
    @include fontsize(50);
    font-weight: bold;

    white-space: nowrap;

    @include tablet{
        @include fontsize(37);
    }
    @include tablet{
        @include fontsize(34);
    }
    @include tablet{
        @include fontsize(32);
    }
    @include tablet{
        @include fontsize(30);
    }
}
.boxes__box__compare {
    @include fontsize(14);
    font-style: italic;
}
.boxes__box__subtitle {
    @include fontsize(14);
    font-weight: bold;
}
.boxes__box__title + .boxes__box__subtitle {
    margin-top: 20px;
}
.boxes__box__title + .boxes__box__compare {
    margin-top: 5px;
}
.boxes__box__compare + .boxes__box__subtitle {
    margin-top: 20px;
}
.boxes__box--green {
    background-color: $blue-green;
}
.boxes__box--purple {
    background-color: $blue;
}
.boxes__box--red {
    background-color: $red;
}
.boxes__box--red-2 {
    background-color: $red-2;
}
.boxes__box--blue {
    background-color: $blue;
}
.boxes__box--blue-light {
    background-color: $blue-light;
}
.boxes__box--blue-2 {
    background-color: $blue-2;
}
.boxes__box--blue-lighter {
    background-color: $blue-lighter;
}