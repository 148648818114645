/***********************************
// ██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝
// ██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║███████╗
// ██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║╚════██║
// ██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║███████║
// ╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝
// 1. <button> reset
// 2. Buttons styles
***********************************/
@mixin button($color, $background, $uppercase: false){
    font-family: $raleway;
	display: inline-block;
    padding: 12px 27px;
    border-radius: 10px;
    border: 3px solid $background;
    background-color: $background;
    color: $color;
    font-size: 16px; line-height: 20px;
    font-weight: 700;
	text-decoration: none;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s all ease-out;

    @if ($uppercase == true) {
        text-transform: uppercase;
    }

    &:hover {
        background-color: transparent;
        color: $background;
    }

    @include medium{
        font-size: 14px; line-height: 18px;
    }
}

@mixin button-filter($color, $background){
    display: inline-block;
    color: $color;
    fill: $color;
    @include fontsize(14,16);
    font-weight: 400;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    background-color: $background;
    outline: none;
    cursor: pointer;
    border: 1px solid $color;
    transition: .2s all ease-out;

    & svg {
        fill: $color;
        width: 25px; height: 25px;
    }

    input:checked + &,
    &.is-active,
    &:hover {
        color: $background;
        fill: $background;
        background-color: $color;

        & svg {
            fill: $background;
        }
    }
}

.button {
    @include button($white, $blue);
}
