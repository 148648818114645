/**
 * Native WordPress Login form
 */
body.login {
    padding-top: 0;
}

#login {
    padding-top: 5%;
}

.login h1 a {
    background-image: url("../../images/groupe-nice-matin-small.png");
    background-size: 169px;
    height: 43px;
    width: 169px;
}

.login form:before {
    content: 'Connexion';
    display: block;
    color: $blue;
    font-family: $raleway;
    @include fontsize(24);
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}
.login form {
    border-radius: 10px;
}

.login label {
    display: block;
    color: $black;
    margin-bottom: 15px;
    @include fontsize(20);
}

.login form .input, 
.login input[type="text"], 
.login input[type="password"] {
    @include input($grey-light);
}

.login .forgetmenot label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
}
.login form .forgetmenot {
    float: none;
    text-align: center;
}
.login.wp-core-ui .button.button-large {
    @include button($white, $blue);
    float: none;
}

.login .button.wp-hide-pw {
    min-width: 50px;
    min-height: 50px;
}

.login form input[type="checkbox"] {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}

#login form p.submit {
    text-align: center;
    margin-top: 10px;
}

.login input[type="checkbox"]:checked::before {
    margin: -3px 0 0 -5px;
    height: 21px;
    width: 21px;
}



/**
 * Login on the front page
 */
.dashboardLogin {
    display: flex;
    align-content: center;
    justify-content: center;
}
.dashboardLogin #loginform {
    display: flex;
    flex-direction: column;
}
.dashboardLogin #loginform > p {
    padding: 15px 0;
}
.dashboardLogin .login-username label,
.dashboardLogin .login-password label {
    @include label();
}
.dashboardLogin .login-username input,
.dashboardLogin .login-password input {
    @include input($grey-light);
}