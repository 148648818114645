/**************************************************
* Manage titles
**************************************************/

.manageTitles__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}
.manageTitles__head--hidden {
    display: none;
}

.manageTitles__head__info {
    display: flex;
    align-items: center;
    @include fontsize(18);
    font-family: $poppins;
    font-weight: 400;
    max-width: 60%;
}
.manageTitles__head__info--warning,
.manageTitles__head__info--error {
    color: $red;
    fill: $red;
}
.manageTitles__head__info--success {
    color: $green;
    fill: $green;
}
.manageTitles__head__info--notice {
    color: $blue-green;
    fill: $blue-green;
}
.manageTitles__head__info__icon {
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

.manageTitles__head__action__button {
    @include button($white, $blue-green);

    & input[type=file] {
        display: none;
    }
}

.manageTitles__subNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
}
.manageTitles__subNav__item {
    padding: 3px;
}
.manageTitles__subNav__item__radio {
    position: absolute;
    left: -9999px;
}
.manageTitles__subNav__item__label {
    @include button-filter($blue-green, $white);
}



/**************************************************
* POPIN
**************************************************/
.manageTitlesPopin {
    display: none;
    position: fixed; z-index: 9999;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%; height: 100%;
    padding-top: 100px;
    background-color: $white;
    overflow: hidden;
}
.manageTitlesPopin__container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.manageTitlesPopin__head {
    position: fixed;
    top: 0; left: 0; right: 0;
    width: 100%; height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: $blue-light;
    box-shadow: 0 0 6px 1px $blue-light;
}
.manageTitlesPopin__head__action {
    display: flex;
    align-items: center;
}
.manageTitlesPopin__head__action__cancel {
    @include fontsize(16);
    cursor: pointer;
    margin-right: 25px;

    &:hover {
        text-decoration: underline;
    }
}
.manageTitlesPopin__head__action__submit {
    @include button($white, $blue-green);
}
.manageTitlesPopin__head__title {
    @include fontsize(24);
    font-family: $poppins;
    font-weight: 700;
    color: $blue;
}

.manageTitlesPopin__content {
    width: 1480px;
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 30px;
}
.manageTitlesPopin__head__action__submit__tooltip {
    margin-top: -32px !important;
}
.manageTitlesPopin__head__action__submit__tooltip .tooltip__content__arrow {
    margin-top: 9px;
}

/* Toggle checkbox */
.manageTitles__toggleContainer {
    text-align: center;
    font-family: $raleway;
}
.manageTitles__toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
}
.manageTitles__toggle__input {
    opacity: 0; width: 0; height: 0;
}
.manageTitles__toggle__helper {
    position: absolute;
    cursor: pointer;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: $grey-light;
    border: 2px solid $blue;
    transition: .4s;
    border-radius: 26px;

    &:before {
        content: "";
        height: 14px; width: 14px;
        position: absolute;
        left: 2px; bottom: 2px;
        background-color: $white;
        border: 2px solid $blue;
        transition: .4s;
        border-radius: 50%;
    }
}
.manageTitles__toggle__left {
    position: absolute;
    right: 100%;
    margin-right: 7px;
    transition: .4s color;
    font-weight: bold;
    color: $blue;
}
.manageTitles__toggle__right {
    position: absolute;
    left: 100%;
    margin-left: 7px;
    transition: .4s color;
}
.manageTitles__toggle__input:checked + .manageTitles__toggle__helper .manageTitles__toggle__left {
    font-weight: normal;
    color: $black;
}
.manageTitles__toggle__input:checked + .manageTitles__toggle__helper .manageTitles__toggle__right {
    font-weight: bold;
    color: $blue;
}
.manageTitles__toggle__input:focus + .manageTitles__toggle__helper {
    //box-shadow: 0 0 1px $blue;
}
.manageTitles__toggle__input:checked + .manageTitles__toggle__helper:before {
    left: calc(100% - 20px);
}