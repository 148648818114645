/**************************************************
* Sidebar
**************************************************/
.sidebar {
    height: 100%;
    position: relative;
    color: $white;

    @include tablet{
        height: $logoHeight;
    }
}

.sidebar__logo {
    display: inline-block;
    height: $logoHeight;
    margin-bottom: 40px;

    @include tablet{
        margin-bottom: 0;
    }
    
    img {
        height: $logoHeight;

        @include medium{
            padding-right: 10px;
        }

        & .text {
            fill: $white;
        } 
    }
}

.sidebarNavWrapper {
    height: calc(100% - 83px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet{
        position: fixed; z-index: -1;
        top: -100%; left: 0; right: 0; bottom: 0;
        background: $blue;
        height: calc(100% - #{$logoHeight+10*2});
        padding: 10px;
        align-items: center;
        opacity: 0;
        transition: .4s all ease-in-out;

        &.opened {
            opacity: 1;
            top: #{$logoHeight+10*2};
        }
    }
}


.sidebar__nav {
    overflow-y: auto;
    padding-right: 20px;
    color: $blue-lighter;
    
    @include tablet{
        padding-right: 10px;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($blue-light, .4);
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $blue-green;
        outline: none;
        border-radius: 50px;
    }
}
.sidebar__nav__list {list-style: none;}

.sidebarNav__parentItem + .sidebarNav__parentItem {
    margin-top: 15px;
}
.sidebarNav__parentItem.active .sidebarNav__title {
    color: $white;
}

.sidebarNav__childItem {
    position: relative;
    
    &.active {
        color: $white;
        
        &:before {
            content: '';
            height: 100%;
            width: 5px;
            background-color: $white;
            position: absolute;
            left: -20px; top: 0; bottom: 0;
        }
    }
    & + & {margin-top: 8px;}
}

.sidebarNav__title__text {
    @include fontsize(18,24);
    font-weight: 700;
    text-decoration: none;
    transition: .2s color ease-out;
}
a.sidebarNav__title__text:hover {
    color: $white;

    .dashboardNav & {
        color: $blue-light;
    }
}
.sidebarNav__title__number {
    @include fontsize(27,32);
    font-weight: 500;
    display: inline-block;
    width: 35px;
}

.sidebarNav__childList {
    padding-left: 38px;
    margin-top: 5px;
    list-style: none;

    @include tablet{
        padding-left: 20px;
    }
}
.sidebarNav__childItem__link {
    text-decoration: none;
    transition: .2s color ease-out;
    
    &:hover {
        color: $white;

        .dashboardNav & {
            color: $blue-light;
        }
    }
}


.sidebar__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-top: 30px;
    
    @include tablet{
        padding-right: 10px;
    }
}
.sidebar__user {
    color: $white;
    font-weight: 700;
    margin-right: 7px;
}
.sidebar__logout {
    display: block;
    width: 20px; height: 20px;
    svg {
        fill: $blue-lighter;
        width: 20px; height: 20px;
        transition: .2s fill ease-out;
    }
    
    &:hover svg {fill: $white;}
}


.sidebarNav__toggleMenu {
    display: none;
    position: absolute;
    top: 0; right: 0;
    width: $logoHeight; height: $logoHeight;
    justify-content: center;
    align-items: center;
    
    border: none;
    border-radius: 0;
    background-color: transparent;
    appearance: none;
    z-index: 10;

    &:focus {
        outline: 0;
    }

    &.opened {
        .sidebarNav__toggleMenu__topBar {
            // animation: animMenuOpenTop 0.2s ease-in-out forwards;
            transform : rotate(45deg) translate(2.5px, 6px);
        }
        .sidebarNav__toggleMenu__middleBar {
            opacity: 0;
            transition-delay: 0s;
        }
        .sidebarNav__toggleMenu__bottomBar {
            // animation: animMenuOpenBottom 0.2s ease-in-out forwards;
            transform: rotate(-45deg) translate(2.5px, -6px);
        }
    }
    &.closed {
        .sidebarNav__toggleMenu__topBar {
            animation: animMenuCloseTop 0.2s ease-in-out forwards;
        }
        .sidebarNav__toggleMenu__middleBar {
            opacity: 1;
        }
        .sidebarNav__toggleMenu__bottomBar {
            animation: animMenuCloseBottom 0.2s ease-in-out forwards;
        }
    }

    @include tablet{
        display: flex;
    }
}

$barHeight: 2px;
$barSpacing: 4px;
.sidebarNav__toggleMenu__icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    
    span {
        position: absolute;
        left: 0;
        background-color: $white;
        width: 100%;
        height: $barHeight;
    }
}
.sidebarNav__toggleMenu__topBar {
    top: 0;
}
.sidebarNav__toggleMenu__middleBar {
    top: $barHeight + $barSpacing;
    transition: 0.2s ease-in-out;
}
.sidebarNav__toggleMenu__bottomBar {
    top: 2*($barHeight + $barSpacing);
}