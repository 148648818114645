/**************************************************
* Aday Covers list
**************************************************/
.adayCoverList {
    display: flex;
    flex-wrap: wrap;
}

.adayCoverList__item {
    width: 25%;
    padding: 25px;
    border-color: $blue-2;
    border-width: $innerPadding;
}

.adayCoverList__itemContent {
    position: relative;
    cursor: pointer;
    border: 1px solid rgba($blue, 0.9);
    border-bottom: 5px;
}
.adayCoverList__item__date {
    width: 100%;
    text-align: center;
    color: $white;
    background-color: rgba($blue, 0.9);
    font-size: 14px;
    line-height: 2em;
}
.adayCoverList__itemContent:hover .adayCoverList__item__date {
    transform: translateY(0);
}
.adayCoverList__itemContent__img {
    display: block;
}


.adayCoverModal {
    position: fixed; z-index: 100;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba($blue-light, 0.6);
    display: none;

    &.active {
        display: block;
    }
}
.adayCoverModal__close {
    position: absolute;
    top: 10px; right: 10px;
    fill: $blue-light;
}
.adayCoverModal__close__icon {
    width: 20px;
    height: 20px;
}
.adayCoverModal__content {
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 40px;
    border: 2px solid $blue;
    background-color: $grey-light;

    display: flex;
    align-items: flex-end;
}
.adayCoverModal__column {
    padding: 15px;
}
.adayCoverModal__column__title {
    color: $blue-light;
    fill: $blue-light;
    font-weight: 700;
    @include fontsize(20,22);
    margin-bottom: 20px;
    text-align: center;
}
.adayCoverModal__column__title--icon {
    margin-bottom: 13px;
}
.adayCoverModal__column__title__icon {
    width: 30px;
    height: 30px;
}
.adayCoverModal__column__content {
    padding: 10px;
    background-color: $blue-lighter;
}
.adayCoverModal__columnLine {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}
.adayCoverModal__columnLine--center {
    justify-content: center;
}
.adayCoverModal__columnLine--positive {
    color: $green;
}
.adayCoverModal__columnLine--negative {
    color: $red;
}
.adayCoverModal__columnLine__label {
    padding-right: 10px;
    color: $blue-light;
}

.adayCoverModal__column__cover {
    margin-bottom: 10px;
}
.adayCoverModal__column__cover__img {
    width: 320px;
    display: block;
    border: 2px solid $blue-lighter;
}