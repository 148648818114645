* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;

	&.disableScroll,
	&.disableScroll body {
		overflow: hidden;
	}
}

body{
	position: relative;
	color: $black;
	text-size-adjust: 100%;

	font-family: $raleway;
	@include fontsize(16,20);
	font-weight: 400;
}

/*
 * GLOBAL HELPERS
 */

.screen-reader-response,
.visuallyhidden {
	/*hide to humans only, screenreaders can still read it*/
	position: absolute;
	width: 1px; /* Setting this to 0 make it invisible for VoiceOver */
	height: 1px; /* Setting this to 0 make it invisible for VoiceOver */
	padding: 0;
	margin: -1px;
	border: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}


::-webkit-input-placeholder {
	font-style: italic;
	color: #b6c5ce;
	font-size: 16px;
}

:-moz-placeholder {
	font-style: italic;
	color: #b6c5ce;
	font-size: 16px;
}

::-moz-placeholder {
	font-style: italic;
	color: #b6c5ce;
	font-size: 16px;
}

input[type="submit"] {
	cursor: pointer;
}

/*fix safari */
input[type="submit"],
input[type="search"] {
	-webkit-appearance: none;
}

input, button, textarea, select {
	font: inherit;
}

.code {
	font-family: monospace;
}

a {
	color: inherit;
}

img {
	max-width: 100%;
	height: auto;
}

strong, b {
	font-weight: bold;
}

em, i {
	font-style: italic;
}

sup {
	top: -0.5em;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	font-size: 0.6em;
}

sub {
	top: 0.5em;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	font-size: 0.6em;
}