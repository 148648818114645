/**************************************************
// ██╗  ██╗███████╗ █████╗ ██████╗ ██╗███╗   ██╗ ██████╗ ███████╗
// ██║  ██║██╔════╝██╔══██╗██╔══██╗██║████╗  ██║██╔════╝ ██╔════╝
// ███████║█████╗  ███████║██║  ██║██║██╔██╗ ██║██║  ███╗███████╗
// ██╔══██║██╔══╝  ██╔══██║██║  ██║██║██║╚██╗██║██║   ██║╚════██║
// ██║  ██║███████╗██║  ██║██████╔╝██║██║ ╚████║╚██████╔╝███████║
// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝
// 1. Headings & Titles mixins
// 2. Titles
// 3. Headings
**************************************************/

@mixin title1($color: $black){
    color: $color;
    font-family: $poppins;
    @include fontsize(34);
    font-weight: 700;

    @include tablet {
        @include fontsize(28);
    }
}
@mixin title2($color: $black){
    color: $color;
    font-family: $poppins;
    @include fontsize(28);
    font-weight: 700;

    @include tablet {
        @include fontsize(24);
    }
}
@mixin title3($color: $black){
    color: $color;
    font-family: $poppins;
    @include fontsize(24);

    @include tablet {
        @include fontsize(22);
    }
}
@mixin title4($color: $black){
    color: $color;
    font-family: $poppins;
    @include fontsize(22);

    @include tablet {
        @include fontsize(20);
    }
}

h1 {
    @include title1();
}

h2 {
    @include title2();
}

h3 {
    @include title3();
}

h4 {
    @include title4();
}

h5 {

}

h6 {

}