.wysiwyg {
    p {margin-bottom: 10px;}
    b, strong, em {font-weight: 600}
    i {font-style: italic;}
    a {
        text-decoration: underline;
        &:hover {text-decoration: none;}
    }
    ul {
        margin: 5px 0;
        padding-left: 24px;
        li {list-style-type: disc;}
    }
    ol {
        margin: 5px 0;
        padding-left: 24px;
        li {list-style-type: decimal;}
    }
    blockquote, q {
        padding: 10px 20px; margin: 10px 0;
        position: relative; color: rgba(0,0,0,0.7);
        background-color: rgba(215, 187, 163, 0.15);
        &::before {content: "«"}
        &::after {content: "»"}
        p {display: inline;}
    }
    h1 {
        font-size: 54px;
        line-height: 60px;
        margin-bottom: 0.5em;
    }
    h2 {
        font-size: 44px;
        line-height: 50px;
        margin-bottom: 0.5em;
    }
    h3 {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 0.5em;
    }
    h4 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 0.5em;
    }
    h5 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0.5em;
    }
    h6 {
        font-weight: bold;
    }
}