/* Fonts */
$raleway: 'raleway', Arial, Helvetica, sans-serif;
$poppins: 'poppins', Arial, Helvetica, sans-serif;

/* Colors */
$black: #000000;
$white: #ffffff;
$grey: #555555;
$grey-2: #BDBDBD;
$grey-light: #f6f6f6;
$blue: #00122f;
$blue-2: #2e24ff;
$blue-3: #2F80ED;
$blue-light: #213b86;
$blue-lighter: #c4ceed;
$blue-lighter-2: #7e8eb7;
$red: #e30613;
$red-2: #E80E4C;
$green: #198619;
$green-flashy: #24FF00;
$blue-green: #66c9cc;

/* Dimensions */
$innerWidth: 1240px;
$innerPadding: 20px;
$innerPadding-mobile: 10px;