/**************************************************
* Tables and stuff inside it
**************************************************/
.dashboardTable__container {
    overflow-x: auto;
}
.dashboardTable {
    @include fontsize(16, 20);
    min-width: 990px;
}
.dashboardTable + .dashboardTable {
    margin-top: 20px;
}
.dashboardTable__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $blue-light;
    font-weight: 700;
    color: $white;
}
.dashboardTable__head__cell {
    flex: 1;
    text-align: right;
    transition: all .2s;
}
.dashboardTable__head__cell:first-child {
    text-align: left;
}
.dashboardTable__head__cell--centered {
    text-align: center;
}
.dashboardTable__head__select {
    color: $white;
    font-family: $raleway;
    @include fontsize(16);
    font-weight: 700;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    outline: none;
    border: 2px solid transparent;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
    transition: .2s border-color ease-out;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTQuNTg4MjkgNy43Njk2QzQuNjM0MTYgNy44NDA2NiA0LjY5NTU1IDcuODk4NzUgNC43NjcxNSA3LjkzODg5QzQuODM4NzUgNy45NzkwMyA0LjkxODQzIDggNC45OTkzMiA4QzUuMDgwMjEgOCA1LjE1OTg5IDcuOTc5MDMgNS4yMzE1IDcuOTM4ODlDNS4zMDMxIDcuODk4NzUgNS4zNjQ0OCA3Ljg0MDY2IDUuNDEwMzUgNy43Njk2TDkuOTEwNzEgMC44MzY3NDNDOS45NjI4IDAuNzU2Nzc4IDkuOTkzMzUgMC42NjMxMTggOS45OTkwMyAwLjU2NTkzOEMxMC4wMDQ3IDAuNDY4NzU3IDkuOTg1MzIgMC4zNzE3NzMgOS45NDI5NSAwLjI4NTUyNEM5LjkwMDU4IDAuMTk5Mjc0IDkuODM2ODYgMC4xMjcwNTcgOS43NTg3MSAwLjA3NjcxOTFDOS42ODA1NiAwLjAyNjM4MTEgOS41OTA5NyAtMC4wMDAxNTI3MDYgOS40OTk2NyA2LjYxMTAzZS0wN0gwLjQ5ODk3QzAuNDA3ODg1IDAuMDAwNDAxOTE4IDAuMzE4NjI1IDAuMDI3Mjc3IDAuMjQwNzkgMC4wNzc3MzU1QzAuMTYyOTU1IDAuMTI4MTk0IDAuMDk5NDg5OSAwLjIwMDMyNyAwLjA1NzIxOTYgMC4yODYzNzdDMC4wMTQ5NDkzIDAuMzcyNDI3IC0wLjAwNDUyNjk0IDAuNDY5MTM5IDAuMDAwODg1NDY4IDAuNTY2MTEyQzAuMDA2Mjk3ODcgMC42NjMwODQgMC4wMzYzOTQxIDAuNzU2NjQ4IDAuMDg3OTM3NiAwLjgzNjc0M0w0LjU4ODI5IDcuNzY5NloiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=) no-repeat top 53% right 5px;
    background-size: 10px;

    &:hover {
        border-color: $blue-lighter;
    }
}
.dashboardTable__head__cell--sortable {
    position: relative;
    cursor: pointer;
    transition: color .1s ease-out;

    & span {
        position: relative;
        padding-right: 14px;
        transition: padding-right .1s ease-out;
    }
    & span:after {
        content: '';
        border-color: #ffffff transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        margin-top: -2px;
        position: absolute;
        top: 50%; right: 0;
        width: 0; height: 0;
        transition: all .15s ease-out;
    }

    &:hover {
        color: $blue-lighter;
        & span:after {
            border-color: $blue-lighter transparent transparent transparent;
        }
    }
}
.dashboardTable__head__cell--sortable.is-active {
    text-decoration: underline;
}
.dashboardTable__head__cell--sortable.sort-asc {
    & span:after {
        border-color: transparent transparent #ffffff transparent;
        border-width: 0 4px 5px 4px;
    }
    &:hover {
        & span:after {
            border-color: transparent transparent $blue-lighter transparent;
        }
    }
}

.dashboardTable__head__select__option {
    color: $blue;
}

.dashboardTable--rounded .dashboardTable__head {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dashboardTable--rounded .dashboardTable__content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dashboardTable--rounded.dashboardTable--has-footer .dashboardTable__content:not(.dashboardTable__content--footer) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.dashboardTable__content {
    padding: 10px 0;
    border: 1px solid $grey-2;
}
.dashboardTable__content__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;

    &:not(.dashboardTable__content__line--sectionTitle):not(.dashboardTable__content__line--no-hover):hover {
        background-color: $grey-light;
    }
}
.dashboardTable__content__line--bold {
    font-weight: 700;
}
.dashboardTable__content__line--hidden {
    display: none;
}
.dashboardTable__content__line--hidden-field {
    display: none;
}
.dashboardTable__content__line--sectionTitle {
    font-weight: 700;
    color: $blue-green;
    text-transform: uppercase;
}
.dashboardTable__content__line + .dashboardTable__content__line--sectionTitle {
    padding-top: 30px;
}
.dashboardTable__content__line__cell {
    flex: 1;
    position: relative;
}
.dashboardTable__content__line .dashboardTable__content__line__cell:not(:first-child) {
    //font-family: monospace;
    & .formGroup__input {
        font-family: monospace;
    }
}
.dashboardTable__content__line__cell,
.dashboardTable__content__line__cell .formGroup__input {
    text-align: right;
}
.dashboardTable__content__line__cell:first-child {
    text-align: left;
}
.dashboardTable__content__line__cell--input {
    width: 320px;
}
.dashboardTable__content__line__cell__link {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
.dashboardTable__content__line__cell--highlight {
    color: $blue-green;
}
.dashboardTable__content__line__cell__floatingUnit {
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
}
.formGroup__input--readonly + .dashboardTable__content__line__cell__floatingUnit {
    right: -8px;
}
.dashboardTable__content__line__cell--tooltip {
    display: flex;
    align-items: center;
}
.dashboardTable__content__line__cell__compare {
    @include fontsize(12);
    font-style: italic;
}
.dashboardTable__content__line__cell--centered {
    text-align: center;
}

.dashboardTable__content--footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
}
.dashboardTable__legend {
    @include fontsize(14);
    font-style: italic;
    color: $grey-2;
    margin-top: 5px;
}


.dashboardTable__evol {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dashboardTable__evol--up {
    fill: $green-flashy;

    .dashboardTable__evol__icon {
        transform: rotate(0deg);
    }
}
.dashboardTable__evol--down {
    fill: $red;

    .dashboardTable__evol__icon {
        transform: rotate(180deg);
    }
}
.dashboardTable__evol__icon {
    width: 15px; height: 15px;
    margin-left: 7px;
    transition: all .2s ease-out;
}
.dashboardTable__evol--hidden {
    fill: transparent;
}

.dashboardTable__collect__icon {
    width: 25px; height: 25px;
    padding: 3px;
    border: 3px solid $white;
    border-radius: 100%;
}
.dashboardTable__collect__icon--success {
    fill: $green;
    border-color: $green;
}
.dashboardTable__collect__icon--error {
    fill: $red;
    border-color: $red;
}

.dashboardTable__import__templateLink {
    font-weight: 700;
    color: $blue-green;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}



.dashboardTableFilters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include fontsize(14);
}
.dashboardTableFilter__item + .dashboardTableFilter__item:before {
    content: "|";
    margin: 0 13px 0 16px;
}
.dashboardTableFilter__item__filter {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
    &.is-active {
        color: $blue-green;
    }
}

.dashboardTable--datatable {
    width: 100%;

    .dashboardTable__content__line {
        border-left: 1px solid #bdbdbd;
        border-right: 1px solid #bdbdbd;
    }
    .dashboardTable__head__cell:first-child {
        text-align: right;
    }
    .dashboardTable__content__line__cell:first-child {
        text-align: right;
    }
    .dashboardTable__head__cell:hover {
        color: $blue-lighter;
    }
}
table.dashboardTable--datatable.dataTable {
    margin-top: 20px;
    margin-bottom: 20px;

    thead th, thead td {
        padding: 0 20px 0 0;
        border: none;
    }
    tbody th, tbody td {
        padding: 0;
    }
}

.dashboardTable__container .dataTables_wrapper {
    display: flex;
    flex-wrap: wrap;

    .dataTables_length,
    .dataTables_filter,
    .dataTables_info,
    .dataTables_paginate {
        width: 50%;
        float: none;
    }

    .dataTables_paginate .paginate_button {
        background: $blue-lighter;
        padding: 4px 9px 3px;
        transition: all .2s;

        &:hover {
            background: $blue-light;
        }

        &.current {
            background: $blue-lighter-2;
            &:hover {
                background: $blue-light;
                color: $white !important;
            }
        }
    }
}