@use 'sass:math';


/**************************************************
* INPUTS VARIATIONS
**************************************************/
$colorTitles: $blue;
$colorInput: $black;
$backgroundInput: $grey-light;

$heightInput: 45;

$fontInput: $raleway;

@mixin label() {
    display: block;
    color: $colorTitles;
    margin-bottom: 15px;
    @include fontsize(20);

    @include tablet(){
        @include fontsize(16);
    }
}

@mixin placeholder($color: $colorTitles, $font-size: 18) {
    color: $color !important;
    @include fontsize($font-size, $font-size+2);
    font-weight: 400;
    opacity: 1 !important;
    text-overflow: ellipsis !important;
    transition: .2s color ease-out;

    @include tablet(){
        @include fontsize(14, 15);
    }
}

@mixin input($backgroundInput: transparent, $color: $black, $font-size: 18) {
    display: block;
    color: $color;
    font-family: $fontInput;
    @include fontsize($font-size,20);
    width: 100%;
    height: #{$heightInput}px;
    padding: 10px;
    border: 2px solid $grey-2;
    border-radius: 10px;
    background-color: $backgroundInput;
    resize: vertical !important;
    transition: .2s border-color ease-out, .2s outline ease-out;

    &:focus{
        outline: 0;
        border-color: $blue-lighter;
    }

    &::placeholder{
        @include placeholder(scale-color($color, $lightness: 50%), $font-size);
    }
}

@mixin select($color, $border_color) {
    display: block;
    color: $color;
    font-family: $NeueMontreal;
    @include fontsize(16,24);
    font-weight: 400;
    padding: 15px 50px 15px 30px;
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid $border_color;
    cursor: pointer;
    transition: .2s border-color ease-out;

    appearance: none;
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00LjU4ODI5IDcuNzY5NkM0LjYzNDE2IDcuODQwNjYgNC42OTU1NSA3Ljg5ODc1IDQuNzY3MTUgNy45Mzg4OUM0LjgzODc1IDcuOTc5MDMgNC45MTg0MyA4IDQuOTk5MzIgOEM1LjA4MDIxIDggNS4xNTk4OSA3Ljk3OTAzIDUuMjMxNSA3LjkzODg5QzUuMzAzMSA3Ljg5ODc1IDUuMzY0NDggNy44NDA2NiA1LjQxMDM1IDcuNzY5Nkw5LjkxMDcxIDAuODM2NzQzQzkuOTYyOCAwLjc1Njc3OCA5Ljk5MzM1IDAuNjYzMTE4IDkuOTk5MDMgMC41NjU5MzhDMTAuMDA0NyAwLjQ2ODc1NyA5Ljk4NTMyIDAuMzcxNzczIDkuOTQyOTUgMC4yODU1MjRDOS45MDA1OCAwLjE5OTI3NCA5LjgzNjg2IDAuMTI3MDU3IDkuNzU4NzEgMC4wNzY3MTkxQzkuNjgwNTYgMC4wMjYzODExIDkuNTkwOTcgLTAuMDAwMTUyNzA2IDkuNDk5NjcgNi42MTEwM2UtMDdIMC40OTg5N0MwLjQwNzg4NSAwLjAwMDQwMTkxOCAwLjMxODYyNSAwLjAyNzI3NyAwLjI0MDc5IDAuMDc3NzM1NUMwLjE2Mjk1NSAwLjEyODE5NCAwLjA5OTQ4OTkgMC4yMDAzMjcgMC4wNTcyMTk2IDAuMjg2Mzc3QzAuMDE0OTQ5MyAwLjM3MjQyNyAtMC4wMDQ1MjY5NCAwLjQ2OTEzOSAwLjAwMDg4NTQ2OCAwLjU2NjExMkMwLjAwNjI5Nzg3IDAuNjYzMDg0IDAuMDM2Mzk0MSAwLjc1NjY0OCAwLjA4NzkzNzYgMC44MzY3NDNMNC41ODgyOSA3Ljc2OTZaIiBmaWxsPSIjMkUyNDhDIi8+Cjwvc3ZnPgo=) no-repeat top 53% right 23px;
    background-size: 10px;


    &:hover {
        border-color: $color;
    }
    &:focus{
        outline: 0;
    }

    @include tablet(){
        padding: 13px 40px 13px 20px;
    }
}

@mixin radio {

    $radioWidth: 22px;
    $radioHeight: 22px;

    $radioOffsetTop: 2px;
    $radioOffsetLeft: 0;

    $radioInnerWidth: 14px;
    $radioInnerHeight: 14px;

    $radioInnerOffsetTop: math.div( ( $radioOffsetTop + ($radioHeight - $radioInnerHeight) ), 2 );
    $radioInnerOffsetLeft: math.div( ( $radioWidth - $radioInnerWidth ), 2 );

	position: relative;

	&:before{
		display: block;
		position: absolute;
		content: '';
		top: $radioOffsetTop;
		left: $radioOffsetLeft;
		width: $radioWidth;
		height: $radioHeight;
		border: solid 2px $blue;
        border-radius: 50%;
        background-color: transparent;
        z-index: 1;
        box-sizing: border-box;
        //@include transition;
		transition-duration: 0.15s;
	}

	&:after{
		opacity: 0;
		visibility: hidden;
		display: block;
		position: absolute;
		content: '';
		top: $radioInnerOffsetTop;
		left: $radioInnerOffsetLeft;
		width: $radioInnerWidth;
        height: $radioInnerHeight;
        border: solid 2px $blue;
        border-radius: 50%;
        background-color: $blue;
        box-sizing: border-box;
        z-index: 2;
        transform: scale(0,0);
		//@include transition;
		transition-duration: 0.15s;
	}

	&.checked{

		&:before{
			//border: solid 1px $pink;
			//background-color: $pink;
		}

		&:after{
			opacity: 1;
            visibility: visible;
            transform: scale(1,1);
		}
	}

	& input[type="radio"]{
		position: absolute;
		left: -9999px;
	}

	& label{
		cursor: pointer;
		@include fontsize(14,$radioHeight);
		padding: 0 0 0 #{$radioWidth + 6};
		margin: 0;
		z-index: 3;
		vertical-align: middle;
	}

}

/**************************************************
* AUTOFILL
**************************************************/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: $colorInput !important;
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	background-color: transparent !important;
}


/**************************************************
* SELECT
**************************************************/
.select2-container.select2BigSelect {
    color: $blue;
    font-family: $raleway;
    font-weight: 700;
    letter-spacing: -0.03em;

    .select2-selection--single {
        height: auto;
        padding: 10px 50px 10px 15px;
        background: transparent;
        border: 2px solid transparent;
        border-radius: 10px;
        outline: none;
        text-decoration: none;
        transition: .2s border-color ease-out;

        &:hover {
            border-color: $blue-lighter;
        }
        &:focus {
            border-color: $blue-light;
        }

        @include tablet(){
            @include fontsize(24);
        }

        .select2-selection__rendered {
            color: $white;
            font-family: $raleway;
            @include fontsize(38,42);
            padding: 0;
            white-space: normal;
            text-align: right;
        }

        .select2-selection__arrow {
            height: 100%;
            width: 50px;

            b {
                border-top-color: $white;
                border-width: 14px 10px 0 10px;
                margin-left: -10px;
                margin-top: -7px;
            }
        }
    }
}
.select2-dropdown.select2BigSelect__dropdown {
    border: 2px solid $blue-lighter;
    border-radius: 10px;
}
.select2-container.select2BigSelect.select2-container--open {
    .select2-selection--single {
        border-color: $blue-lighter;

        .select2-selection__arrow b {
            border-top-color: transparent;
            border-bottom-color: $white;
            border-width: 0 10px 14px 10px;
        }
    }
}


/**************************************************
* RADIO
**************************************************/
.input--field-radiogroup {
    display: flex;
    margin: 20px 0;

    & .radio {
        margin-bottom: 0;
        margin-right: 50px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.input--field.radio {

    @include radio();
}



/**************************************************
* GLOBAL FORM
**************************************************/
.form {
    margin: 30px auto;
}
.fieldset {
    margin: 40px 0;
}
.fieldset__legend {
    @include title3($blue-green);
    margin-bottom: 15px;
}
.formGroup {
    & + & {
        margin-top: 15px;
    }
}
.formGroup--center-input-text input {
    text-align: center;
}
.formGroup--error {
    .formGroup__input {
        border-color: lighten($red, 35%);

        &:focus{
            border-color: $red;
        }
    }
}
.formGroup__error {
    color: $red;
    margin-bottom: 7px;
    @include fontsize(14,16);
}
.formGroup__label {
    display: block;
    margin-bottom: 7px;
    @include fontsize(20,26);
}
.formGroup__input {
    @include input();
}
.formGroup__textarea {
    @include input();
    min-height: 140px;
    max-width: 100%;
}
.formGroup__fixWrapper {
    display: flex;
    
    .formGroup__input {
        border-right: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}
.formGroup__suffix {
    min-width: 55px;
    color: $blue-lighter;
    @include fontsize(18,20);
    border: 2px solid $grey;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $grey-light;
    padding: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s border-color ease-out;
}
.formGroup__suffix--middle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
}
.formGroup__suffix--middle + input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.formGroup__input:focus + .formGroup__suffix {
    border-color: $blue-lighter;
}

.formTable {
    .formGroup__input {
        height: auto;
        padding: 6px 10px;
        border-radius: 0;
        @include fontsize(16);
    }
    .formGroup__textarea {
        height: auto;
        padding: 6px 10px;
        border-radius: 0;
        @include fontsize(16);
    }

    .formGroup__suffix {
        flex: none;
        border-radius: 0;
    }
}

.formGroup__input--readonly {
    @include fontsize(16, 20);
    font-weight: 700;
    border: none;
}


/**************************************************
* GRAVITY FORM FIX
**************************************************/
ul.gform_fields {list-style: none;}

.gform_footer {margin-top: 30px;}

li.gfield + li.gfield {margin-top: 15px;} 

.validation_error {
    color: $red;
}

.gform_confirmation_message {
    margin-top: 60px;
}