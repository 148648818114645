/**************************************************
* Dashboard Header
**************************************************/

/**
 * Title in the header
 */
.dashboard__title {
    @include title2();
    color: $white;
}
.dashboard__subtitle {
    color: $blue-green;
}
.dashboard__title + .dashboard__subtitle {
    margin-top: 15px;

    @include tablet{
        margin-top: 10px;
    }
}


/**
 * Title inside content
 */
.dashboardTitle {
    font-family: $poppins;
    color: $blue;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;

    @include tablet{
        max-width: 90%;
    }
}
.dashboardTitle__container {
    position: relative;
    padding: 40px 60px;

    &:before {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 40px; height: 30px;
        border-top: 25px solid $blue-light;
        border-left: 25px solid $blue-light;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0; bottom: 0;
        width: 30px; height: 40px;
        border-right: 25px solid $blue-2;
        border-bottom: 25px solid $blue-2;
    }
}
.dashboardTitle__title {
    @include fontsize(70);
    font-weight: bold;
    text-transform: uppercase;
    color: $blue-light;
    /*color: $white;
    text-shadow: 0 0 1px $black, 1px 0 1px $black, -1px 0 1px $black, 0 1px 1px $black, 0 -1px 1px $black;*/

    @include tablet{
        @include fontsize(60);
    }
    @include tablet{
        @include fontsize(50);
    }
    @include tablet{
        @include fontsize(42);
    }
    @include tablet{
        @include fontsize(36);
    }
    @include tablet{
        @include fontsize(32);
    }
}
.dashboardTitle__subtitle {
    @include fontsize(30);
    text-transform: capitalize;

    @include tablet{
        @include fontsize(29);
    }
    @include tablet{
        @include fontsize(28);
    }
    @include tablet{
        @include fontsize(27);
    }
    @include tablet{
        @include fontsize(26);
    }
}
.dashboardTitle__title + .dashboardTitle__subtitle {
    margin-top: 15px;
}



/**
 * Heading
 */
.dashboard__heading {
    position: relative;
    background-color: $blue-light;
    padding: 25px 40px;
    border-top-left-radius: 20px;

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        z-index: -1;
        width: 30px; height: 30px;
        background-color: $blue;
    }

    @include tablet{
        padding: 25px 20px;
    }

    @include tablet{
        border-top-right-radius: 20px;
    }

    @include tablet{
        margin-bottom: 20px;
    }

    @include tablet{
        padding: 25px 10px;
    }
}
.dashboard__heading--with-nav {
    padding-bottom: 0;
}
.dashboard__heading__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet{
        flex-direction: column;
    }
}

.dashboard__headingLeft {
    padding-right: 15px;

    @include tablet{
        padding-right: 0;
    }
}
.dashboard__headingRight {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;

    @include tablet{
        align-self: center;
        margin-top: 25px;
    }
}
.dashboard__headingRight__item {
    margin: 7px;
    height: 58px;
    & > .documentsList__head__search,
    & > .documentsList__head__timelineButton,
    & > .documentsList__head__filterSelect {
        height: 100%;
    }
}
.dashboard__headingRight__filter {
    display: flex;
    align-items: center;
}

.dashboard__heading__nav {
    @include tablet{
        margin-top: 20px;
    }
}
.dashboard__heading__nav__content {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 8px rgba($blue-light, .4);
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $blue-green;
        outline: none;
        border-radius: 50px;
    }

    @include tablet{

    }
}
.dashboard__heading__nav__item {
    position: relative;
    padding: 15px 15px;
    white-space: nowrap;
}
.dashboard__heading__nav__input {
    position: absolute;
    left: -9999px;
}
.dashboard__heading__nav__label {
    @include fontsize(14);
    font-family: $poppins;
    font-weight: 700;
    color: $blue-green;
    cursor: pointer;
    transition: .2s all ease-out;
    text-decoration: none;

    &:hover {
        color: $blue;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0; right: 0; bottom: -5px;
        height: 5px; width: 100%;
        background-color: $blue;
        transition: .2s bottom ease-out;
    }
}
.dashboard__heading__nav__label.is-active,
.dashboard__heading__nav__input:checked + .dashboard__heading__nav__label {
    color: $blue;
    font-weight: bold;

    &:before {
        bottom: 0;
    }
}



/**
 * Subheading
 */
.dashboard__subHeading {
    position: relative;
    padding: 20px 40px;
    display: flex;
    flex-wrap: nowrap;

    @include tablet{
        padding: 20px 20px;
    }

    @include tablet{
        padding: 20px 10px;
    }
}
.dashboard__subHeading__item {
    display: flex;
    align-items: flex-start;
}
.dashboard__subHeading__item--left {
    width: 75%;

    @include tablet{
        width: 100%;
    }
    @include tablet{
        justify-content: center;
    }
}
.dashboard__subHeading__item--right {
    justify-content: flex-end;
    width: 25%;

    @include tablet{
        display: none;
    }
}
.dashboard__subHeading__subNav {
    display: flex;
    flex-wrap: wrap;

    @include tablet{
        justify-content: center;
    }
}
.dashboard__subHeading__subNav__item {
    padding: 3px;
    display: none;

    &.is-active {
        display: block;
    }
}
.dashboard__subHeading__subNav__input {
    position: absolute;
    left: -9999px;
}
.dashboard__subHeading__subNav__label {
    @include button-filter($blue-green, $white);
}