.anchors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
}
.anchors__item + .anchors__item:before {
    content: "-";
    color: $blue-green;
    margin: 0 9px 0 11px;
}
.anchors__link {
    @include fontsize(14);
    color: $blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}