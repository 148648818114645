/**************************************************
* Dashboard Layout
**************************************************/
$logoHeight: 43px;

.dashboard {
    padding: 0 40px 40px;

    @include tablet{
        padding: 0 15px 40px;
    }
}

.dashboard .xdebug-error {
    margin-left: 15%;
}

.dashboard__mainWrapper {
    display: flex;
    min-height: 100vh;
    background-color: $blue;

    .admin-bar & {min-height: calc(100vh - 32px);}

    @include tablet{
        flex-direction: column;
    }
}

.dashboard__sidebar {
    width: 15%;
    padding: 30px 0 30px 20px;
    position: fixed; z-index: 9999;
    top: 0; left: 0; bottom: 0;
    background-color: $blue;

    @include medium {
        width: 17%;
    }

    @include tablet{
        padding-left: 10px;
    }

    @include tablet{
        width: 100%;
        padding: 10px;
        bottom: unset; right: 0;
    }
}

.dashboard__content {
    position: relative; z-index: 2;
    width: 85%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    padding: 0;
    border-top-left-radius: 20px;
    background-color: $white;
    box-shadow: 0 100px 80px rgba(21, 11, 85, 0.07), 0 53.7916px 59.1167px rgba(21, 11, 85, 0.0562903), 0 30.088px 35.8012px rgba(21, 11, 85, 0.0550939), 0 16.6503px 19.7278px rgba(21, 11, 85, 0.0531374), 0 8.38014px 9.3966px rgba(21, 11, 85, 0.0467797), 0 3.17625px 3.17504px rgba(21, 11, 85, 0.0319453);
    transition: .2s margin-left linear;

    @include tablet{
        width: 100%;
        margin-left: 0;
        border-top-right-radius: 20px;
        margin-top: #{$logoHeight+10*2};
        min-height: calc(100vh - #{$logoHeight+10*2});
    }

}

.dashboard__inner {
    padding: 30px 40px;

    @include tablet{
        padding: 25px 20px;
    }

    @include tablet{
        padding: 15px 10px;
    }
}
.dashboard__inner--no-padding-top {
    padding-top: 0;
}

.dashboardNav {
    display: flex;
    justify-content: center;
}




.dashboardSection {
    margin: 120px 0;
}
.dashboard .dashboardSection:first-child {
    margin-top: 20px;
}
.dashboardSection__title {
    @include fontsize(30,32);
    color: $blue;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;

    span {
        color: $red-2;
    }

    @include tablet{
        @include fontsize(24,26);
    }
}
.dashboardSection__title__forceLowerCase {
    text-transform: lowercase;
}
.dashboardSection__subtitle {
    @include fontsize(18,20);
    color: $blue;
    text-align: center;
    margin-bottom: 10px;
}
.dashboardSection__content {
    text-align: center;
}
.dashboardSection__content__item {
    margin: 20px 0;
}

.dashboardSection__chartContainer {
    overflow-x: auto;
    overflow-y: hidden;
}
.dashboardSection__chart {
    height: 500px;
    min-width: 1400px;
}
.dashboardSection__chart--higher {
    height: 600px;
}
.dashboardSection__chart--xxl {
    height: 700px;
}

.dashboardSection__chart__subtext {
    color: $blue;
    @include fontsize(14);
    text-align: center;
    margin-top: 30px;
}

.dashboardSection__chart__controls {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboardSection__chart__controls__button {
    color: $blue-light;
    margin: 10px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
    &.is-active {
        font-weight: bold;
    }
}

.dashboardSection--subsection {
    display: flex;
    flex-wrap: wrap;
}
.dashboardSection__subsection {
    width: 50%;

    @include tablet{
        width: 100%;
    }
}
.dashboardSection__subsection .dashboardSection__chart {
    min-width: 0;
}